<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";

let { form } = defineProps({ form: Object });

let validHex = (hex, generic) => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(hex) ? hex : generic;
};
</script>

<template>
  <div class="relative h-[370px] w-64 overflow-hidden rounded border border-slate-100 shadow-xl">
    <div class="relative ml-auto h-0 w-[83%] justify-end pt-[71%]">
      <img class="absolute inset-0 h-full w-full object-cover"
           src="/img/cover-preview.jpg" />
    </div>

    <div class="absolute top-[165px] z-10 w-[160px] px-4">
      <transition name="fade"
                  mode="out-in">
        <div :key="form.font_primary"
             class="text-[13px] font-bold uppercase"
             :class="form.font_primary"
             :style="{
              color: validHex(form.text_colour, '#FFFFFF'),
            }">
          Building &amp; Pest Inspection
        </div>
      </transition>
    </div>

    <div class="absolute bottom-4 z-10 w-full px-5">
      <transition name="fade"
                  mode="out-in">
        <div :key="form.font_secondary"
             :class="form.font_secondary"
             class="border-b-[1.5px] pb-1"
             :style="{
              borderColor: validHex(form.primary_colour, '#EF4444'),
            }">
          <div :class="form.font_secondary"
               class="mt-1 text-[7px] font-bold uppercase"
               :style="{
                color: validHex(form.secondary_colour, '#29343B'),
                textAlign: form.text_alignment,
                opacity: 0.5,
              }">
            1st January 2023
          </div>
          <div :class="form.font_secondary"
               class="mt-1 text-[9px] font-semibold"
               :style="{
                color: validHex(form.secondary_colour, '#29343B'),
                textAlign: form.text_alignment,
              }">
            123 Test Street, Sydney, NSW, 2000
          </div>
        </div>
      </transition>
    </div>

    <svg class="absolute top-0"
         viewBox="0 0 595 745"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M474 0H0V181.5L80.5 228.5L474 0Z"
            fill="white" />
      <path d="M49 220L292 80V135L49 275V220Z"
            :fill="validHex(form.primary_colour, '#EF4444')" />
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 716.693L49 745V635L0 606.693V716.693Z"
            :fill="validHex(form.primary_colour, '#EF4444')"
            fill-opacity="0.8" />
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M595 319.578L49 635V745L595 429.578V319.578Z"
            :fill="validHex(form.primary_colour, '#EF4444')" />
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49 635.5L0 607V310V177.789L595 522V631L325.693 475.205L49 635.5Z"
            :fill="validHex(form.secondary_colour, '#29343B')" />
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49 635.5L0 607V310V177.789L595 522V631L325.694 475.205L49 635.5Z"
            :fill="validHex(form.secondary_colour, '#29343B')" />

      <path d="M0 177.786L595 522V631L0 287V177.786Z"
            fill="white"
            fill-opacity="0.05" />
      <defs>
        <clipPath id="clip0_3774_777">
          <rect width="595"
                height="800"
                fill="white"
                transform="translate(0 178)" />
        </clipPath>
        <pattern id="brickPattern"
                 x="0"
                 y="0"
                 width="16"
                 height="8"
                 patternUnits="userSpaceOnUse">
          <rect width="22"
                height="14"
                x="1"
                y="1"
                fill="white"
                fill-opacity="0.02" />
        </pattern>
      </defs>
      <rect width="595"
            height="800"
            fill="url(#brickPattern)" />
    </svg>

    <div class="absolute left-3 top-3 h-10 w-20">
      <div v-if="form.logo">
        <img :src="`/images/logos/${form.logo}`"
             class="h-10 w-24 object-contain" />
      </div>
      <div v-else>
        <div class="flex h-10 w-20 items-center justify-end">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke-width="1.5"
                 stroke="currentColor"
                 class="h-5 w-5 text-slate-300">
              <path stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
          <div class="ml-1 text-[10px] font-bold uppercase tracking-tighter text-slate-400">
            Logo here
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
